
import { mapState } from 'vuex'

import CreatorAsset from '~/repository/creatorImage'

const eyeAccordionIcon = require('~/assets/images/landing-page/eye-accordion.svg')

export default {
  data() {
    return {
      eyeAccordionIcon,
      selectedCategories: {},
      sliderSettings: {
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 100,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              touchThreshold: 100,
            },
          },
        ],
      },
      categorySliderIndex: 0,
      transitionState: false,
    }
  },
  computed: {
    ...mapState({
      locale: state => state.settings.locale,
      countryUser: state => state.settings.countryUser,
    }),
    categoriesSingaporeMalaysia() {
      return [
        {
          key: 'artist', text: 'Artist', title: this.$t('artist'),
        },
        {
          key: 'cosplay', text: 'Cosplay', title: this.$t('cosplay'),
        },
        {
          key: 'streamers', text: 'Streamers', title: this.$t('streamers'),
        },
        {
          key: 'lifestyle', text: 'Lifestyle', title: this.$t('lifestyle'),
        },
        {
          key: 'vtube', text: 'VTuber', title: this.$t('vtuber'),
        },
      ]
    },
    categoriesPhilippines() {
      return [
        { key: 'cosplay', text: 'Cosplay', title: this.$t('cosplay') },
        { key: 'craft', text: 'Craft', title: this.$t('craft') },
        { key: 'idol', text: 'Idol', title: this.$t('idol') },
        { key: 'musician', text: 'Musician', title: this.$t('musician') },
        { key: 'vtube', text: 'VTuber', title: this.$t('vtuber') },
      ]
    },
    categoriesThailand() {
      return [
        { key: 'art', text: 'Art', title: this.$t('art') },
        { key: 'cosplay', text: 'Cosplay', title: this.$t('cosplay') },
        { key: 'gaming', text: 'Gaming', title: this.$t('gaming') },
        { key: 'craft', text: 'Craft', title: this.$t('craft') },
        { key: 'health', text: 'Health & Fitness', title: this.$t('health_and_fitness') },
        { key: 'lifestyle', text: 'Lifestyle', title: this.$t('lifestyle') },
        { key: 'vtube', text: 'VTuber', title: this.$t('vtuber') },
      ]
    },
    categoriesVietnam() {
      return [
        { key: 'cosplay', text: 'Cosplay', title: this.$t('cosplay') },
        { key: 'gaming', text: 'Gaming', title: this.$t('gaming') },
        { key: 'streaming', text: 'Streaming', title: this.$t('streaming') },
        { key: 'vtube', text: 'VTuber', title: this.$t('vtuber') },
      ]
    },
    categoriesIndonesia() {
      return [
        { key: 'art', text: 'Art', title: this.$t('art') },
        { key: 'asmr', text: 'ASMR', title: this.$t('asmr') },
        { key: 'cosplay', text: 'Cosplay', title: this.$t('cosplay') },
        { key: 'craft', text: 'Craft', title: this.$t('craft') },
        { key: 'food', text: 'Food & Drink', title: this.$t('food_and_drink') },
        { key: 'gaming', text: 'Gaming', title: this.$t('gaming') },
        { key: 'lifestyle', text: 'Lifestyle', title: this.$t('lifestyle') },
        { key: 'vtube', text: 'VTuber', title: this.$t('vtuber') },
      ]
    },
    categoriesInternational() {
      return [
        { key: 'asmr', text: 'ASMR', title: this.$t('asmr') },
        { key: 'animation', text: 'Animation', title: this.$t('animation') },
        { key: 'craft', text: 'Craft', title: this.$t('craft') },
        { key: 'cosplay', text: 'Cosplay', title: this.$t('cosplay') },
        { key: 'gaming', text: 'Gaming', title: this.$t('gaming') },
        { key: 'health', text: 'Health & Fitness', title: this.$t('health_and_fitness') },
        { key: 'lifestyle', text: 'Lifestyle', title: this.$t('lifestyle') },
        { key: 'vtube', text: 'VTuber', title: this.$t('vtuber') },
      ]
    },
    categories() {
      const country = {
        SG: this.categoriesSingaporeMalaysia,
        MY: this.categoriesSingaporeMalaysia,
        TW: this.categoriesSingaporeMalaysia,
        PH: this.categoriesPhilippines,
        TH: this.categoriesThailand,
        VN: this.categoriesVietnam,
        ID: this.categoriesIndonesia,
      }

      return country[this.countryUser] ?? this.categoriesInternational
    },
    creatorImage() {
      const country = {
        TH: CreatorAsset.TH,
        VN: CreatorAsset.VI,
        SG: CreatorAsset.SGMY,
        MY: CreatorAsset.SGMY,
        TW: CreatorAsset.SGMY,
        PH: CreatorAsset.PH,
        ID: CreatorAsset.ID,
      }

      return country[this.countryUser] ?? CreatorAsset.World
    },
    creatorImageByCountry() {
      const list = {
        TH: 'art',
        VN: 'cosplay',
        SG: 'artist',
        MY: 'artist',
        TW: 'artist',
        PH: 'cosplay',
        ID: 'art',
      }

      if (['TH', 'VN', 'SG', 'MY', 'PH', 'ID', 'TW'].includes(this.countryUser)) {
        return this.creatorImage[this.selectedCategories?.key] ?? this.creatorImage[list[this.countryUser]]
      }

      return this.creatorImage[this.selectedCategories?.key] ?? this.creatorImage?.asmr
    },
    transitionName() {
      return this.transitionState ? 'slide-delay' : 'slide'
    },
  },
  mounted() {
    this.selectCategories(this.categories[0])
  },
  methods: {
    moreCategories() {
      this.$router.push('/discover')
      this.$gtm.push({
        event: 'landing_category_more',
      })
    },
    selectCategories(item, index) {
      this.selectedCategories = item
      if (this.$refs.categorySlider) {
        this.$refs.categorySlider.goTo(index)
      }
      this.$gtm.push({
        event: 'landing_category',
      })
      this.$gtm.push({
        event: `landing_category_${item.text.replaceAll(' ', '_').replace('&', 'and').toLowerCase()}`,
      })
    },
    beforeSlideChange(oldSlideIndex, newSlideIndex) {
      if (oldSlideIndex > newSlideIndex) {
        this.transitionState = true
      } else {
        this.transitionState = false
      }
    },
    afterSlideChange(slideIndex) {
      this.categorySliderIndex = slideIndex
      this.selectedCategories = this.categories[slideIndex]
    },
  },
}
