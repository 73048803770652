// ID
const indonesiaArtImage = require('~/assets/images/landing-page/creator/indonesia/art.png')
const indonesiaArtSticker = require('~/assets/images/landing-page/creator/indonesia/art-sticker.png')
const indonesiaASMRImage = require('~/assets/images/landing-page/creator/indonesia/asmr.png')
const indonesiaASMRSticker = require('~/assets/images/landing-page/creator/indonesia/asmr-sticker.png')
const indonesiaCosplayImage = require('~/assets/images/landing-page/creator/indonesia/cosplay.png')
const indonesiaCosplaySticker = require('~/assets/images/landing-page/creator/indonesia/cosplay-sticker.png')
const indonesiaCraftImage = require('~/assets/images/landing-page/creator/indonesia/craft.png')
const indonesiaCraftSticker = require('~/assets/images/landing-page/creator/indonesia/craft-sticker.png')
const indonesiaFoodImage = require('~/assets/images/landing-page/creator/indonesia/food.png')
const indonesiaFoodSticker = require('~/assets/images/landing-page/creator/indonesia/food-sticker.png')
const indonesiaGamingImage = require('~/assets/images/landing-page/creator/indonesia/gaming.png')
const indonesiaGamingSticker = require('~/assets/images/landing-page/creator/indonesia/gaming-sticker.png')
const indonesiaLifeStyleImage = require('~/assets/images/landing-page/creator/indonesia/lifestyle.png')
const indonesiaLifeStyleSticker = require('~/assets/images/landing-page/creator/indonesia/lifestyle-sticker.png')
const indonesiaVtubeImage = require('~/assets/images/landing-page/creator/indonesia/vtube.png')
const indonesiaVtubeSticker = require('~/assets/images/landing-page/creator/indonesia/vtube-sticker.png')

// PH
const philippinesCosplayImage = require('~/assets/images/landing-page/creator/philippines/cosplay.png')
const philippinesCosplaySticker = require('~/assets/images/landing-page/creator/philippines/cosplay-sticker.png')
const philippinesCraftImage = require('~/assets/images/landing-page/creator/philippines/craft.png')
const philippinesCraftSticker = require('~/assets/images/landing-page/creator/philippines/craft-sticker.png')
const philippinesIdolImage = require('~/assets/images/landing-page/creator/philippines/idol.png')
const philippinesIdolSticker = require('~/assets/images/landing-page/creator/philippines/idol-sticker.png')
const philippinesMusicianImage = require('~/assets/images/landing-page/creator/philippines/musician.png')
const philippinesMusicianSticker = require('~/assets/images/landing-page/creator/philippines/musician-sticker.png')
const philippinesVtubeImage = require('~/assets/images/landing-page/creator/philippines/vtube.png')
const philippinesVtubeSticker = require('~/assets/images/landing-page/creator/philippines/vtube-sticker.png')

// SG
const singaporeArtistImage = require('~/assets/images/landing-page/creator/singapore/artist.png')
const singaporeArtistSticker = require('~/assets/images/landing-page/creator/singapore/artist-sticker.png')
const singaporeCosplayImage = require('~/assets/images/landing-page/creator/singapore/cosplay.png')
const singaporeCosplaySticker = require('~/assets/images/landing-page/creator/singapore/cosplay-sticker.png')
const singaporeStreamersImage = require('~/assets/images/landing-page/creator/singapore/streamers.png')
const singaporeStreamersSticker = require('~/assets/images/landing-page/creator/singapore/streamers-sticker.png')
const singaporeLifeStyleImage = require('~/assets/images/landing-page/creator/singapore/lifestyle.png')
const singaporeLifeStyleSticker = require('~/assets/images/landing-page/creator/singapore/lifestyle-sticker.png')
const singaporeVtubeImage = require('~/assets/images/landing-page/creator/singapore/vtube.png')
const singaporeVtubeSticker = require('~/assets/images/landing-page/creator/singapore/vtube-sticker.png')

// TH
const thailandArtImage = require('~/assets/images/landing-page/creator/thailand/art.png')
const thailandArtSticker = require('~/assets/images/landing-page/creator/thailand/art-sticker.png')
const thailandCosplayImage = require('~/assets/images/landing-page/creator/thailand/cosplay.png')
const thailandCosplaySticker = require('~/assets/images/landing-page/creator/thailand/cosplay-sticker.png')
const thailandCraftImage = require('~/assets/images/landing-page/creator/thailand/craft.png')
const thailandCraftSticker = require('~/assets/images/landing-page/creator/thailand/craft-sticker.png')
const thailandGamingImage = require('~/assets/images/landing-page/creator/thailand/gaming.png')
const thailandGamingSticker = require('~/assets/images/landing-page/creator/thailand/gaming-sticker.png')
const thailandhealthImage = require('~/assets/images/landing-page/creator/thailand/health.png')
const thailandhealthSticker = require('~/assets/images/landing-page/creator/thailand/health-sticker.png')
const thailandLifeStyleImage = require('~/assets/images/landing-page/creator/thailand/lifestyle.png')
const thailandLifeStyleSticker = require('~/assets/images/landing-page/creator/thailand/lifestyle-sticker.png')
const thailandVtubeImage = require('~/assets/images/landing-page/creator/thailand/vtube.png')
const thailandVtubeSticker = require('~/assets/images/landing-page/creator/thailand/vtube-sticker.png')

// VI
const vietnamCosplayImage = require('~/assets/images/landing-page/creator/vietnam/cosplay.png')
const vietnamCosplaySticker = require('~/assets/images/landing-page/creator/vietnam/cosplay-sticker.png')
const vietnamGamingImage = require('~/assets/images/landing-page/creator/vietnam/gaming.png')
const vietnamGamingSticker = require('~/assets/images/landing-page/creator/vietnam/gaming-sticker.png')
const vietnamStreamingImage = require('~/assets/images/landing-page/creator/vietnam/streaming.png')
const vietnamStreamingSticker = require('~/assets/images/landing-page/creator/vietnam/streaming-sticker.png')
const vietnamVtubeImage = require('~/assets/images/landing-page/creator/vietnam/vtube.png')
const vietnamVtubeSticker = require('~/assets/images/landing-page/creator/vietnam/vtube-sticker.png')

export default class CreatorAsset {
  static ID = {
    art: { key: 'art', image: indonesiaArtImage, sticker: indonesiaArtSticker },
    asmr: { key: 'asmr', image: indonesiaASMRImage, sticker: indonesiaASMRSticker },
    cosplay: { key: 'cosplay', image: indonesiaCosplayImage, sticker: indonesiaCosplaySticker },
    craft: { key: 'craft', image: indonesiaCraftImage, sticker: indonesiaCraftSticker },
    food: { key: 'food', image: indonesiaFoodImage, sticker: indonesiaFoodSticker },
    gaming: { key: 'gaming', image: indonesiaGamingImage, sticker: indonesiaGamingSticker },
    lifestyle: { key: 'lifestyle', image: indonesiaLifeStyleImage, sticker: indonesiaLifeStyleSticker },
    vtube: { key: 'vtube', image: indonesiaVtubeImage, sticker: indonesiaVtubeSticker },
  }

  static PH = {
    cosplay: { key: 'cosplay', image: philippinesCosplayImage, sticker: philippinesCosplaySticker },
    craft: { key: 'craft', image: philippinesCraftImage, sticker: philippinesCraftSticker },
    idol: { key: 'idol', image: philippinesIdolImage, sticker: philippinesIdolSticker },
    musician: { key: 'gaming', image: philippinesMusicianImage, sticker: philippinesMusicianSticker },
    vtube: { key: 'vtube', image: philippinesVtubeImage, sticker: philippinesVtubeSticker },
  }

  static SGMY = {
    artist: { key: 'artist', image: singaporeArtistImage, sticker: singaporeArtistSticker },
    cosplay: { key: 'cosplay', image: singaporeCosplayImage, sticker: singaporeCosplaySticker },
    gaming: { key: 'streamers', image: singaporeStreamersImage, sticker: singaporeStreamersSticker },
    lifestyle: { key: 'lifestyle', image: singaporeLifeStyleImage, sticker: singaporeLifeStyleSticker },
    vtube: { key: 'vtube', image: singaporeVtubeImage, sticker: singaporeVtubeSticker },
  }

  static TH = {
    art: { key: 'art', image: thailandArtImage, sticker: thailandArtSticker },
    cosplay: { key: 'cosplay', image: thailandCosplayImage, sticker: thailandCosplaySticker },
    craft: { key: 'craft', image: thailandCraftImage, sticker: thailandCraftSticker },
    gaming: { key: 'gaming', image: thailandGamingImage, sticker: thailandGamingSticker },
    health: { key: 'health', image: thailandhealthImage, sticker: thailandhealthSticker },
    lifestyle: { key: 'lifestyle', image: thailandLifeStyleImage, sticker: thailandLifeStyleSticker },
    vtube: { key: 'vtube', image: thailandVtubeImage, sticker: thailandVtubeSticker },
  }

  static VI = {
    cosplay: { key: 'cosplay', image: vietnamCosplayImage, sticker: vietnamCosplaySticker },
    gaming: { key: 'gaming', image: vietnamGamingImage, sticker: vietnamGamingSticker },
    streaming: { key: 'streaming', image: vietnamStreamingImage, sticker: vietnamStreamingSticker },
    vtube: { key: 'vtube', image: vietnamVtubeImage, sticker: vietnamVtubeSticker },
  }

  static World = {
    asmr: { key: 'asmr', image: indonesiaASMRImage, sticker: indonesiaASMRSticker },
    animation: { key: 'animation', image: singaporeArtistImage, sticker: singaporeArtistSticker },
    craft: { key: 'craft', image: philippinesCraftImage, sticker: philippinesCraftSticker },
    cosplay: { key: 'cosplay', image: thailandCosplayImage, sticker: thailandCosplaySticker },
    gaming: { key: 'gaming', image: vietnamGamingImage, sticker: vietnamGamingSticker },
    health: { key: 'health', image: thailandhealthImage, sticker: thailandhealthSticker },
    lifestyle: { key: 'lifestyle', image: indonesiaLifeStyleImage, sticker: indonesiaLifeStyleSticker },
    vtube: { key: 'vtube', image: indonesiaVtubeImage, sticker: indonesiaVtubeSticker },
  }
}
